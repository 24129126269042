<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-multiline">
        <div class="column">
          <label class="label">
            NOMBRE
          </label>
          <p>
            {{ name }}
          </p>
        </div>
        <div class="column">
          <label class="label">
            CORREO ELECTRÓNICO
          </label>
          <p>
            {{ email }}
          </p>
        </div>
        <div class="column">
          <label class="label">
            FECHA DE REGISTRO
          </label>
          <p>
            {{ formatDate(registrationDate, 'DD/MM/YYYY') }}
          </p>
        </div>
        <div class="column">
          <label class="label">
            ESTATUS
          </label>
          <p>
            <input
              id="switchStatus"
              type="checkbox"
              name="switchStatusStatus"
              class="switch is-rounded is-small"
              :checked="status ? 'checked' : ''"
              @change="$emit('update-customer-status-action')"
            >
            <label for="switchStatus"></label>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins/formats'

export default {
  name: 'UserInformation',
  mixins: [mixins],
  props: {
    name: {
      type: String,
      default: null
    },
    email: {
      type: String,
      default: null
    },
    registrationDate: {
      type: String,
      default: null
    },
    status: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }
</style>
